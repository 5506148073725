import { useEffect, useState } from 'react';

function App() {
  const [isRedirected, setIsRedirected] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "https://athlioconnect.com/";
      setIsRedirected(true);
    }, 1000); // Adjust the delay if necessary

    return () => clearTimeout(timer); // Cleanup if the component unmounts
  }, []);

  return (
    <div className="App">
      {isRedirected ? (
        <p>If you are not redirected automatically, please <a href="https://athlioconnect.com/">click here</a>.</p>
      ) : (
        <p>Redirecting to AthlioConnect...</p>
      )}
    </div>
  );
}

export default App;
